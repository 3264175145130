import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import VerificationModal from './2faModal';

type userInfo = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
}

export type auto2faApi = {
  setUserData: (user: userInfo | null) => void;
  getUserData: () => userInfo | null;
  openModal: () => void;
  closeModal: () => void;
  isVerified: () => boolean;
}

const USER_STORE_TOKEN = '2fa-user-store';
const EXP_STORE_TOKEN = '2fa-exp-store';
const EXP_TIME_MS = 14.5 * 60 * 1000; // 14.5 minutes in ms

function refreshFromStore<T>(key: string, noRefresh?: boolean): T | null {
  if (noRefresh) return null;
  return JSON.parse(sessionStorage.getItem(key) || 'null') ?? null;
}

export function Auto2fa({
  apiRef,
  onSuccess,
  noRehydrate,
  onRehydrate,
}: {
  apiRef?: MutableRefObject<auto2faApi | null>;
  onSuccess?: () => void;
  noRehydrate?: boolean;
  onRehydrate?: () => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [isReconfirm, setIsReconfirm] = useState<boolean>(false);

  const [user, setUser] = useState<userInfo | null>(refreshFromStore(USER_STORE_TOKEN, noRehydrate));
  useEffect(() => {
    sessionStorage.setItem(USER_STORE_TOKEN, JSON.stringify(user));
  }, [user]);

  const [exp, setExp] = useState<number | null>(refreshFromStore(EXP_STORE_TOKEN, noRehydrate));
  useEffect(() => {
    sessionStorage.setItem(EXP_STORE_TOKEN, JSON.stringify(exp));
    if (exp) {
      const timeout = setTimeout(() => {
        setIsReconfirm(true);
        setOpen(true);
      }, exp - Date.now());
      return () => clearTimeout(timeout);
    }
  }, [exp, setOpen]);

  const api = useMemo(() => ({
    getUserData: () => user,
    setUserData: setUser,
    openModal: () => setOpen(true),
    closeModal: () => setOpen(false),
    isVerified: () => (!!exp && exp > Date.now()),
  }), [user, setUser, exp]);

  // set api ref
  useEffect(() => {
    if (apiRef) {
      apiRef.current = api;
    }
  }, [apiRef, api]);

  // call on success if initial refresh is successful
  useEffect(() => {
    if (api.isVerified()) {
      onRehydrate?.();
    }
  }, []);

  console.log('is verified: ', !!exp && exp > Date.now());

  if (open) {
    return (
      <VerificationModal
        onClose={() => setOpen(false)}
        onSuccess={() => {
          setOpen(false);
          const expTime = Date.now() + EXP_TIME_MS;
          setExp(expTime);
          onSuccess?.();
        }}
        phoneNumber={user?.phone || ''}
        email={user?.email || ''}
        firstName={user?.firstName || ''}
        lastName={user?.lastName || ''}
        isReconfirm={isReconfirm}
      />
    );
  }
  return <></>;
}
