import { gql, TypedDocumentNode, useMutation } from '@apollo/client';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import useChargeCustomer from '../../../common_lib_front/hooks/useChargeCustomer';
import useGetMyGuests from '../../../common_lib_front/hooks/useMyGuest';
import useOrderPrice, { PassPrice } from '../../../common_lib_front/hooks/useOrderPrice';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { PaymentStatuses } from '../../../common_lib_front/types/passInfo';
import { backendClient } from '../../../common_lib_front/utilities/BackendAPI';
import { formatDateForInput } from '../../../common_lib_front/utilities/formatDate';
import { Auto2fa } from '../../../components/2faModal/auto2fa';
import useVehicleConfigs, { PassInfoData } from '../../../utils/useVehicleConfigs';
import { mutateWrapperForCreateCustomer } from './mutateWrapperForCreateCustomer';
import style from './paymentRequestPage.module.css';

const SEND_GUEST_PASS = gql`
  mutation sendGuestPass(
    $firstName: String!
    $lastName: String!
    $email: String!
    $userId: String!
    $phone: String!
    $registrationId: String!
  ) {
    sendGuestPass(
      firstName: $firstName
      lastName: $lastName
      email: $email
      userId: $userId
      phone: $phone
      registrationId: $registrationId
    ) {
      success
      error
    }
  }
`;

const ADVANCE_STEP_NUMBER: TypedDocumentNode<
  {
    editRegistrationStepNumber: backendResponse<undefined>;
  },
  {
    registrationId: string;
  }
> = gql`
  mutation AdvanceStepNum($registrationId: String!) {
    editRegistrationStepNumber(stepNumber: 5, registrationId: $registrationId) {
      success
      error
    }
  }
`;

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export default function PaymentRequest(): ReactElement {
  const { communityId } = useContext(CommunityContext);
  const { propertySlug, registrationId } = useParams<{
    propertySlug: string;
    registrationId: string;
  }>();
  const history = useHistory();
  const [error, setError] = useState<string>();
  const targetDivRef = useRef<HTMLDivElement | null>(null);
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [billingZip, setBillingZip] = useState('');

  const [passInfoId, setPassInfoId] = useState<PassInfoData[]>([]);
  const {
    data: propertyDetail,
    loading: queryLoading,
    error: propertyError,
  } = useProperty({ propertySlug: propertySlug || '' });

  const { passInfoDatas, vehicleConfig } = useVehicleConfigs();

  const {
    data: myGuestDetails,
    error: guestDetailsError,
    loading,
    refetch: refetchGuestData,
  } = useGetMyGuests({
    registrationIds: registrationId ? [registrationId] : [],
    propertySlug: propertySlug,
  });

  useEffect(() => {
    refetchGuestData();
  }, [refetchGuestData]);

  useEffect(() => {
    if (myGuestDetails) {
      const allPassesUnpaidOrNoChargeAndInactive = myGuestDetails.every(guestDetail =>
        guestDetail.passes.every(
          pass =>
            pass.paid === PaymentStatuses.unpaid ||
            (pass.paid === PaymentStatuses.noCharge && pass.status === 'incomplete'),
        ),
      );
      if (!allPassesUnpaidOrNoChargeAndInactive) {
        // All passes are either unpaid or no-charge and inactive, redirect to get passes URL
        // history.push(`/guest/${propertySlug}/get-passes`);
      }
    }
  }, [myGuestDetails]);

  const {
    charge,
    data: chargeCustomerData,
    loading: chargeCusLoading,
    error: chargeCusError,
  } = useChargeCustomer();

  useEffect(() => {
    const passInfoId = passInfoDatas.filter(
      passInfoData => passInfoData.portal === 'guest',
    );

    setPassInfoId(passInfoId);
  }, [passInfoDatas]);

  const today = new Date().toISOString().split('T')[0];
  let userId = '';

  // Calculate total count and amount for each pass type
  const createSamplePasses = (passInfoIds: PassInfoData[]) => {
    return passInfoIds.map(passInfo => ({
      passInfoId: passInfo.passInfoId,
      startDate: myGuestDetails?.[0]?.communityRental?.arrivalDate,
      endDate: myGuestDetails?.[0]?.communityRental?.departureDate,
    }));
  };

  // Will comment this for now. Carson, please remove it, in case it does not have a purpose anymore.
  // const samplePasses = useMemo(
  //   () => createSamplePasses(passInfoId),
  //   [
  //     passInfoId,
  //     myGuestDetails?.[0]?.communityRental?.arrivalDate,
  //     myGuestDetails?.[0]?.communityRental?.departureDate,
  //   ],
  // );

  const rId = myGuestDetails?.[0]?.registration?.registrationId;
  const orderPricePayload =
    myGuestDetails?.[0]?.passes
      ?.filter(pass => pass.paid === PaymentStatuses.unpaid)
      .map(p => {
        return { passId: p.passId, passInfoId: p.passInfoId, registrationId: rId };
      }) ?? [];
  const {
    passes: passesData,
    fees,
    totalPrice,
    loading: orderPriceLoading,
  } = useOrderPrice(orderPricePayload);

  const passPriceInfo = useMemo(() => {
    const res = {} as Record<string, PassPrice>;
    passesData?.forEach(p => {
      res[p.passInfoId] = p;
    });
    return res;
  }, [passesData]);

  const calculateTotals = () => {
    return passInfoDatas
      .filter(passInfoData => passInfoData.portal === 'guest')
      .map(passInfo => {
        // Filter and calculate the number of passes and total amount for each passInfo
        const matchingPasses = myGuestDetails?.[0]?.passes.filter(
          pass =>
            pass.passInfoId === passInfo.passInfoId &&
            pass.paid === PaymentStatuses.unpaid,
        );

        const totalCount = matchingPasses?.length || 0;
        const passPrice = passPriceInfo[passInfo.passInfoId]?.price || 0; // Ensure passPrice is defined
        const totalAmount = totalCount * passPrice;

        return {
          passType: passInfo.name,
          totalCount,
          totalAmount,
        };
      });
  };

  const sendGuestPass = async () => {
    if (myGuestDetails && myGuestDetails[0]?.guestInfo?.userId) {
      const { data } = await backendClient.mutate({
        mutation: SEND_GUEST_PASS,
        fetchPolicy: 'no-cache',
        variables: {
          firstName: myGuestDetails[0]?.guestInfo?.firstName,
          lastName: myGuestDetails[0]?.guestInfo?.lastName,
          email: myGuestDetails[0]?.guestInfo?.email,
          userId: myGuestDetails[0]?.guestInfo?.userId,
          phone: myGuestDetails[0]?.guestInfo?.phoneNumber,
          registrationId: myGuestDetails[0]?.registration?.registrationId,
        },
      });
    }
  };

  // DANGEROUS: NEVER CALCULATE PRICES ON THE FRONT END
  const aggregateTotals = () => {
    const totals = calculateTotals();

    // Initialize totals
    let totalCount = 0;
    let totalAmount = 0;

    // Aggregate totals from all pass types
    totals.forEach(({ totalCount: count, totalAmount: amount }) => {
      totalCount += count;
      totalAmount += amount;
    });

    return {
      totalCount,
      // totalAmount DANGEROUS DO NOT USE - FEES NOT ACCOUNTED FOR
    };
  };

  const { totalCount } = aggregateTotals();

  const stripe = useStripe();
  const elements = useElements();

  const isHostLink =
    propertyDetail?.hostPay && propertyDetail.hostPropertySlug === propertySlug;
  const isGuestLink =
    propertyDetail?.guestPay && propertyDetail.guestPropertySlug === propertySlug;

  const [doAdvanceStepNum] = useMutation(ADVANCE_STEP_NUMBER);

  const handleSubmit = async () => {
    setError('');
    setPaymentProcessing(true);

    if (paymentProcessing) return false;

    if (isHostLink) {
      // temporary commented
      if (!propertyDetail?.userId) {
        setError('Host is invalid');
      }
      // TODO: only call this after a successful charge or if there is no charge
      await doAdvanceStepNum({
        variables: { registrationId },
      });
      if (!orderPriceLoading && totalPrice === 0) {
        history.push(`/guest/${propertySlug}/${registrationId}/preview-passes`);
      } else {
        if (propertyDetail) {
          const chargeResponse = await charge(
            propertyDetail.userId,
            totalPrice,
            '',
            '',
            isHostLink,
            isGuestLink,
            registrationId,
          );
          if (chargeResponse.response?.data.chargeCustomer.success) {
            history.push(`/guest/${propertySlug}/${registrationId}/preview-passes`);
          } else {
            setError(chargeResponse?.error as unknown as string || 'Something went wrong processing payment');
          }
        }
      }
      setPaymentProcessing(false);
    }

    if (isGuestLink) {
      if (!stripe || !elements) {
        return;
      }
      if (myGuestDetails) {
        userId = myGuestDetails[0]?.guestInfo?.userId;
      }
      if (!orderPriceLoading && totalPrice === 0) {
        // call send Guest Pass API
        sendGuestPass();
        scrollToTop();
        await doAdvanceStepNum({
          variables: { registrationId },
        });
        history.push(`/guest/${propertySlug}/${registrationId}/preview-passes`);
      } else {
        const cardNumber = elements.getElement(CardNumberElement);
        const cardExpiry = elements.getElement(CardExpiryElement);
        const cardCvc = elements.getElement(CardCvcElement);

        if (
          !cardNumber ||
          (cardNumber as any)._invalid ||
          (cardNumber as any)._empty ||
          !cardExpiry ||
          (cardExpiry as any)._invalid ||
          (cardExpiry as any)._empty ||
          !cardCvc ||
          (cardCvc as any)._invalid ||
          (cardCvc as any)._empty
        ) {
          setError('Please complete all card details');
          setPaymentProcessing(false);
          scrollToTop();
          return;
        }

        // if (cardElement) {
        if (userId) {
          const guestEmail = myGuestDetails?.[0]?.guestInfo.email;
          let stripeCustomerId;
          if (guestEmail) {
            const customer = await mutateWrapperForCreateCustomer({ userId: userId });
            stripeCustomerId = customer.createStripeCustomer.data.stripeCustomerId;
          }

          // const paymentMethod = await stripe.createPaymentMethod({
          //   type: 'card',
          //   card: cardElement,
          // });
          const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber, // Using cardNumber element specifically
            billing_details: {
              address: {
                postal_code: billingZip, // Assuming you have a billingZip state
              },
            },
          });

          const paymentMethodId = paymentMethod?.paymentMethod?.id;
          if (paymentMethodId) {
            const { response, error } = await charge(
              userId,
              totalPrice * 100,
              paymentMethodId,
              stripeCustomerId,
              isHostLink,
              isGuestLink,
              registrationId,
            );
            if (response?.data.chargeCustomer.success) {
              // call send Guest Pass API
              sendGuestPass();
              scrollToTop();
              await doAdvanceStepNum({
                variables: { registrationId },
              });
              history.push(`/guest/${propertySlug}/${registrationId}/preview-passes`);
            } else {
              console.log('the error', error);
              setError(error?.message || 'Something went wrong processing payment');
              scrollToTop();
            }
          }
        }
        // }
      }

      setPaymentProcessing(false);
    }
  };

  useEffect(() => {
    if (chargeCustomerData?.chargeCustomer?.success) {
      // navigate to preview page
      history.push(`/guest/${propertySlug}/${registrationId}/preview-passes`);
    } else if (!chargeCustomerData?.chargeCustomer?.success) {
    } else if (!chargeCustomerData?.chargeCustomer?.success) {
      setError(chargeCustomerData?.chargeCustomer?.error);
    }
  }, [chargeCustomerData, history, propertySlug, registrationId]);

  useEffect(() => {
    if (error) {
      if (targetDivRef.current) {
        targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [error]);

  useEffect(() => {
    if (guestDetailsError) {
      setError(guestDetailsError);
    } else if (chargeCusError) {
      setError(chargeCusError.message);
    }
  }, [guestDetailsError, chargeCusError]);

  if (loading || queryLoading) {
    return (
      <div className={style.container}>
        <div className={style.box}>
          <div className={style.mainBox}>
            <div className={style.innerBox}>
              <div className={style.loading}></div>
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const ELEMENT_STYLES = {
    base: {
      fontSize: '16px',
      color: '#32325d',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  return (
    <div className={style.container}>
      <div className={`${style.box} white noShadow`}>
        <div className={style.passForm}>
          <h1 className={`${style.subHeaderTitle} textColorBlack`}>
            Complete your pass request.
          </h1>
          <div className={style.mainBox}>
            {error && (
              <div ref={targetDivRef} className={style.dangerDiv}>
                {error}
              </div>
            )}

            {isGuestLink && totalPrice !== 0 && (
              // <div className={style.cardBox}>
              //   <form onSubmit={handleSubmit}>
              //     <CardElement />
              //   </form>
              // </div>
              <div className={style.paymentContainer}>
                <form onSubmit={handleSubmit}>
                  <div className={style.poweredByStripe}>
                    Powered by <span className={style.stripeText}>stripe</span>
                  </div>
                  <div className={`${style.row} ${style.blockRow}`}>
                    <div className={style.box_element}>
                      <label>Card number</label>
                      <div className={style.cardElementContainer}>
                        <CardNumberElement options={{ style: ELEMENT_STYLES }} />
                      </div>
                    </div>
                    <div className={style.box_element}>
                      <div className={style.row}>
                        <div className={style.box__inputField}>
                          <label>Expiration</label>
                          <div className={`${style.cardElementContainer} expiry`}>
                            <CardExpiryElement options={{ style: ELEMENT_STYLES }} />
                          </div>
                        </div>
                        <div className={`${style.box__inputField} ${style.cvvContainer}`}>
                          <label>CVV</label>
                          <div className={style.cardElementContainer}>
                            <CardCvcElement options={{ style: ELEMENT_STYLES }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${style.row} ${style.blockRow}`}>
                    <div className={style.box_element}>
                      <label>Billing ZIP</label>
                      <div className={`${style.cardElementContainer} ${style.col6}`}>
                        <input type="text" placeholder="Add a billing ZIP" />
                      </div>
                    </div>
                  </div>
                </form>

                {/* <style>{`
             
            `}</style> */}
              </div>
            )}
            <div className={style.links}>
              <a
                href={`/${communityId}/guest/terms-conditions`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>
              <a
                href={`/${communityId}/guest/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy
              </a>
            </div>
            <div className={style.cardBox}>
              <h5>Property details</h5>
              <p>
                {propertyDetail?.address}, {propertyDetail?.city} <br />
                {propertyDetail?.state} {propertyDetail?.zipCode}
              </p>
            </div>
            {myGuestDetails && myGuestDetails.length > 0 && (
              <>
                <div className={style.cardBox}>
                  <h5>Guest details</h5>
                  <label>Name</label>
                  <p>
                    {myGuestDetails?.[0]?.guestInfo?.firstName || ''}{' '}
                    {myGuestDetails?.[0].guestInfo?.lastName || ''}
                  </p>
                  <hr className={style.horizontalLine} />
                  <label>Email</label>
                  <p>{myGuestDetails?.[0]?.guestInfo?.email}</p>
                  <hr className={style.horizontalLine} />
                  <label>Phone number</label>
                  <p>{myGuestDetails?.[0]?.guestInfo?.phoneNumber}</p>
                  {/* {myGuestDetails?.[0]?.communityRental?.guestNames instanceof Array && (
                    <>
                      <label>Guest Names</label>
                      <p>{myGuestDetails[0].communityRental.guestNames.join(', ')}</p>
                    </>
                  )} */}
                </div>
                <div className={style.cardBox}>
                  <h5>Pass details</h5>
                  <label>Checkin date</label>
                  <p>
                    {formatDateForInput(
                      myGuestDetails?.[0]?.communityRental?.arrivalDate,
                    )}
                  </p>
                  <hr className={style.horizontalLine} />
                  <label>Checkout date</label>
                  <p>
                    {formatDateForInput(
                      myGuestDetails?.[0]?.communityRental?.departureDate,
                    )}
                  </p>
                  <hr className={style.horizontalLine} />
                  <label>Passes</label>
                  <div className={style.hasRow}>
                    {passInfoDatas
                      .filter(
                        passInfoData =>
                          passInfoData.portal === 'guest' &&
                          myGuestDetails?.[0]?.passes.some(
                            p => p.passInfoId === passInfoData.passInfoId,
                          ),
                      )
                      .map(passInfo => (
                        <React.Fragment key={passInfo.passInfoId}>
                          {/* <div className={style.cardBox}>
                                  <h4 className={style.mb0}>{passInfo.name}</h4>
                                </div> */}
                          <div className={style.row}>
                            {myGuestDetails?.[0]?.passes
                              .filter(p => p.passInfoId === passInfo.passInfoId)
                              .map(pass => {
                                // Get the vehicle configuration for the current pass
                                const vehicleFields =
                                  vehicleConfig[pass.passInfoId] || {};
                                // Define the fields to be rendered
                                const fieldsToShow = Object.entries(vehicleFields)
                                  .filter(([_, value]) => value === 'required') // Include only "required" or "optional" fields
                                  .map(([key]) => key);

                                return (
                                  <div
                                    key={pass.passId}
                                    className={
                                      myGuestDetails?.[0]?.passes.filter(
                                        p => p.passInfoId === passInfo.passInfoId,
                                      ).length > 1
                                        ? style.col6
                                        : style.col6
                                    }
                                  >
                                    <div className={style.cardBox}>
                                      <h4>{passInfo.name}</h4>
                                      <p>
                                        $
                                        {passPriceInfo[
                                          passInfo.passInfoId
                                        ]?.price?.toFixed(2) || 0}{' '}
                                        / pass
                                      </p>
                                      {/* Conditionally render each field based on fieldsToShow configuration */}
                                      {fieldsToShow.includes('licensePlate') && (
                                        <>
                                          <label>License Plate</label>
                                          <p>{pass?.vehicles?.licensePlate || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('type') && (
                                        <>
                                          <label>Vehicle Type</label>
                                          <p>{pass?.vehicles?.type || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('color') && (
                                        <>
                                          <label>Vehicle Color</label>
                                          <p>{pass?.vehicles?.color || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('make') && (
                                        <>
                                          <label>Vehicle Make</label>
                                          <p>{pass?.vehicles?.make || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('vehicleModel') && (
                                        <>
                                          <label>Vehicle Model</label>
                                          <p>{pass?.vehicles?.vehicleModel || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('fleetNumber') && (
                                        <>
                                          <label>Fleet Number</label>
                                          <p>{pass?.vehicles?.fleetNumber || 'N/A'}</p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('licensePlateState') && (
                                        <>
                                          <label>License Plate State</label>
                                          <p>
                                            {pass?.vehicles?.licensePlateState || 'N/A'}
                                          </p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('primaryDriverName') && (
                                        <>
                                          <label>Name</label>
                                          <p>
                                            {pass?.vehicles?.primaryDriverName || 'N/A'}
                                          </p>
                                          <hr />
                                        </>
                                      )}

                                      {fieldsToShow.includes('licensePrimaryDriver') && (
                                        <>
                                          <label>License Primary Driver</label>
                                          <p>
                                            {pass?.vehicles?.licensePrimaryDriver ||
                                              'N/A'}
                                          </p>
                                          <hr />
                                        </>
                                      )}
                                      <>
                                        <label>Valid starting on</label>
                                        <p>
                                          {new Date(pass.startDate)
                                            .toISOString()
                                            .split('T')[0] || 'N/A'}
                                        </p>
                                        <hr />
                                      </>
                                      <>
                                        <label>Valid through</label>
                                        <p>
                                          {new Date(pass.endDate)
                                            .toISOString()
                                            .split('T')[0] || 'N/A'}
                                        </p>
                                        <hr />
                                      </>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </>
            )}
            <h4 className={style.flexHeader}>Subtotal ({totalCount || 0} Passes)</h4>
            {passInfoDatas
              .filter(
                passInfoData =>
                  passInfoData.portal === 'guest' &&
                  myGuestDetails?.[0]?.passes.some(
                    pass =>
                      pass.passInfoId === passInfoData.passInfoId &&
                      pass.paid === PaymentStatuses.unpaid,
                  ),
              )
              .map(passInfo => {
                // Filter and calculate the number of passes and total amount for each passInfo
                const matchingPasses = myGuestDetails?.[0]?.passes.filter(
                  pass =>
                    pass.passInfoId === passInfo.passInfoId &&
                    pass.paid === PaymentStatuses.unpaid,
                );

                const passCount = matchingPasses?.length || 0;
                const totalAmount =
                  passCount * (passPriceInfo[passInfo.passInfoId]?.price || 0);

                return (
                  <div className={style.flexItem} key={passInfo.passInfoId}>
                    <div>
                      {passCount > 0
                        ? `(${passCount}) ${passInfo.name}`
                        : `${passInfo.name}`}
                    </div>
                    {isGuestLink && <div>${totalAmount.toFixed(2)}</div>}
                  </div>
                );
              })}
            {fees.map(fee => (
              <div className={style.flexItem} key={fee.name}>
                <div>{fee.name}</div>
                {isGuestLink && <div>${fee.amount?.toFixed(2)}</div>}
              </div>
            ))}
            {isGuestLink && (
              <>
                <hr className={style.horizontalLine} />
                <div className={style.flexItem}>
                  <h4>Total</h4>
                  <h4>${totalPrice.toFixed(2) || 0}</h4>
                </div>
              </>
            )}
            <p>
              By submitting you agree to our{' '}
              <a
                href={`/${communityId}/guest/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href={`/${communityId}/guest/terms-conditions`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use.
              </a>
            </p>
            <div className={`${style.innerBox} ${style.buttonBox}`}>
              <div className={style.buttonContainer}>
                {!paymentProcessing ? (
                  <button className={style.button} onClick={handleSubmit} type="submit">
                    {`Purchase Passes ${
                      isGuestLink ? `($${totalPrice.toFixed(2) || 0})` : ''
                    }`}
                  </button>
                ) : (
                  <button className={style.button}>Processing...</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Auto2fa />
    </div>
  );
}
