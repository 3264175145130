import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { backendPaginatedResponse } from '../types/backendResponse';
import { backendClient } from '../utilities/BackendAPI';

export const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId, propertyFlow: true) {
      success
      error
      data {
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
        vehicleId
      }
    }
  }
`;

const GET_GUESTS = gql`
  query GetMyGuests(
    $registrationIds: [String!]
    $fastPassesOnly: Boolean
    $hideDeleted: Boolean
    $currentOnly: Boolean
    $pageNumber: Float!
    $pageSize: Float!
    $propertySlug: String!
  ) {
    getMyGuests(
      registrationIds: $registrationIds
      fastPassesOnly: $fastPassesOnly
      hideDeleted: $hideDeleted
      currentOnly: $currentOnly
      pageNumber: $pageNumber
      pageSize: $pageSize
      propertySlug: $propertySlug
    ) {
      success
      error
      numberPages
      data {
        guestInfo {
          userId
          firstName
          lastName
          email
          phoneNumber
        }
        communityRental {
          address
          arrivalDate
          departureDate
          communityRentalId
          guestNames
        }
        passes {
          passId
          startDate
          endDate
          passInfoId
          status
          paid
          url
          registrationId
          passInfo {
            priceInfo {
              chargeForFullCapacity
            }
          }
        }
        registration {
          registrationId
        }
      }
    }
  }
`;

type vars = {
  registrationIds?: string[];
  propertySlug?: string;
};

type res = {
  getMyGuests: backendPaginatedResponse<invitedGuestInfo[]>;
};

export type invitedGuestInfo = {
  guestInfo: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  communityRental: {
    address: string;
    arrivalDate: string;
    departureDate: string;
    communityRentalId: string;
    guestNames?: string[];
  };
  passes: {
    vehicles?: {
      type: string;
      color: string;
      licensePlate: string;
      licensePrimaryDriver: string;
      primaryDriverName: string;
      licensePlateState: string;
      fleetNumber: string;
      vehicleModel: string;
      make: string;
    };
    passId: string;
    startDate: string;
    endDate: string;
    passInfoId: string;
    passInfo?: {
      priceInfo? : {
        chargeForFullCapacity: boolean;
      }
    };
    status: string;
    paid: string;
    url?: string;
    registrationId?: string;
  }[];
  registration: {
    registrationId: string;
  };
};

type useGetMyGuestsRes = {
  data?: invitedGuestInfo[];
  numberPages?: number;
  error?: string;
  loading?: boolean;
  refetch: () => void
};

export default function useGetMyGuests(variables: Partial<vars>): useGetMyGuestsRes {
  const { data, loading, error, refetch } = useQuery<res, vars>(GET_GUESTS, {
    variables: {
      ...variables,
      ...{
        pageNumber: 0,
        pageSize: 15000,
      },
    },
    skip: variables?.registrationIds?.length === 0,
  });
  useEffect(() => {
    if (variables?.registrationIds?.length) {
      refetch();
    }
  }, [variables?.registrationIds]);

  const [guestsWithVehicles, setGuestsWithVehicles] = useState<invitedGuestInfo[]>([]);

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      if (!data?.getMyGuests.data) return;

      const guests = data.getMyGuests.data;

      // Fetch vehicle details for all passes
      const vehiclePromises = guests.flatMap(guest =>
        guest.passes.map(pass =>
          backendClient
            .query({
              query: GET_VEHICLE,
              variables: { passId: pass.passId },
            })
            .then(response => ({
              passId: pass.passId,
              vehicles: response.data.getVehicle?.data,
            })),
        ),
      );

      const vehicleResponses = await Promise.all(vehiclePromises);

      // Create a map of passId to vehicle details
      const vehicleMap = new Map<string, any>();
      vehicleResponses.forEach(({ passId, vehicles }) => {
        vehicleMap.set(passId, vehicles);
      });

      // Update guests with vehicle details
      const updatedGuests = guests.map(guest => ({
        ...guest,
        passes: guest.passes.map(pass => ({
          ...pass,
          vehicles: vehicleMap.get(pass.passId),
        })),
      }));

      setGuestsWithVehicles(updatedGuests);
    };

    fetchVehicleDetails();
  }, [data]);

  return {
    data: guestsWithVehicles || undefined,
    numberPages: data?.getMyGuests.numberPages,
    error:
      data?.getMyGuests.error ||
      (error ? 'Something went wrong. Could not get info.' : undefined),
    loading,
    refetch,
  };
}
