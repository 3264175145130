import React, { useEffect, useState } from 'react';
import { PassPrice } from '../../../common_lib_front/hooks/useOrderPrice';
import { PassInfoData } from '../../../utils/useVehicleConfigs';
import style from './getPasses.module.css';
import { VehiclePass } from './types';
import { PassOptionErrorsType } from './validateVehicleData';
import VehiclePassForm from './vehiclePassForm';

interface PassOptionsProps {
  passInfoId: PassInfoData[];
  vehiclePassesByType: { [passInfoId: string]: VehiclePass[] };
  orderPricePasses: PassPrice[] | undefined;
  paidPassInfoIds: string[],
  days: number;
  startDate: string;
  endDate: string;
  isGuestLink: boolean | undefined;
  priceSums?: Record<string, number> | null;
  errors?: PassOptionErrorsType;
  addVehiclePass: (passInfoId: string, passName: string, capacityOverride: number | null) => void;
  removeVehiclePass: (passInfoId: string) => void;
  handleVehicleChange: (
    passInfoId: string,
    index: number,
    field: keyof VehiclePass,
    value: string,
  ) => void;
  handleCheckboxChange: (passInfoId: string, index: number) => void;
}

const PassOptions: React.FC<PassOptionsProps> = ({
  passInfoId,
  vehiclePassesByType,
  orderPricePasses,
  paidPassInfoIds,
  days,
  startDate,
  endDate,
  isGuestLink,
  priceSums,
  errors,
  addVehiclePass,
  removeVehiclePass,
  handleVehicleChange,
  handleCheckboxChange,
}) => {
  const [newStartDate, setNewStartDate] = useState(startDate);
  useEffect(() => {
    setNewStartDate(startDate);
  }, [startDate]);

  const getDefaultDates = (passInfoData: PassInfoData) => {
    const currentDate = new Date().toISOString().split('T')[0];
    let maxEndDate;
    if (startDate) {
      maxEndDate = new Date(startDate);
    } else {
      maxEndDate = new Date();
    }
    maxEndDate.setDate(
      maxEndDate.getDate() + (passInfoData?.durationInfo?.duration1 || 0),
    );
    const maxEndDateString = maxEndDate.toISOString().split('T')[0];
    const defaultStartDate = startDate || currentDate;
    const defaultEndDate =
      endDate && new Date(endDate) <= maxEndDate ? endDate : maxEndDateString;
    return { defaultStartDate, defaultEndDate, maxEndDateString };
  };

  return (
    <>
      {passInfoId.map(passInfo => {
        const passInfoId = passInfo.passInfoId;
        const passName = passInfo.name;
        const limit = passInfo.capacityOverride;
        const vehiclePasses = vehiclePassesByType[passInfoId] || [];
        const matchedPass = orderPricePasses?.find(
          pass => pass.passInfoId === passInfoId,
        );
        const defaultPassLength =
          matchedPass && matchedPass?.paid === 'paid' ? 0 : vehiclePasses.length;

        const { defaultStartDate, defaultEndDate, maxEndDateString } =
          getDefaultDates(passInfo);
        let priceVal = (passInfo.priceInfo?.price || 0).toFixed(2);
        const chargeForFullCapacity = passInfo.priceInfo?.chargeForFullCapacity ?? false;
        if (vehiclePasses.length >= 1 && chargeForFullCapacity) {
          const ps = priceSums?.[passInfoId];
          if (typeof ps === 'number') {
            priceVal = ps.toFixed(2);
          } else if (priceSums === null) {
            priceVal = '-';
          }
        }
        if (paidPassInfoIds.includes(passInfoId)){
          priceVal = '0.00';
        }
        return (
          <div key={passInfoId} className={`${style.mainInnerBox}`}>
            <div className={style.innerBox}>
              <div className={style.passOptions}>
                <div className={style.passHeader}>
                  <h2>{passInfo.name}</h2>
                  <div className={style.row}>
                    <div>
                      <p style={{ margin: '10px 0px 0px 0px' }}>{passInfo.description}</p>
                    </div>
                    <div className={style.passCounter}>
                      {isGuestLink && (
                        <div className={style.priceContainer}>
                          {passInfo.priceInfo?.price ? (
                            <span className={style.price}>
                              ${priceVal}
                              {!chargeForFullCapacity && '/pass'}
                            </span>
                          ) : (
                            <span>Free</span>
                          )}
                        </div>
                      )}
                      <div className={style.counterContainer}>
                        { (paidPassInfoIds.includes(passInfoId)) ? (
                          <><span className={style.paidLbl}>Paid</span></>
                        ) : (
                          <>
                            <button
                              type="button"
                              className={style.counterButton}
                              onClick={() => removeVehiclePass(passInfoId)}
                              disabled={
                                vehiclePasses.length <=
                                (passInfo.priceInfo?.chargeForFullCapacity ? 1 : 0)}
                            >
                              -
                            </button>
                            <span className={style.counter}>{defaultPassLength}</span>
                            <button
                              type="button"
                              className={style.counterButton}
                              onClick={() => addVehiclePass(passInfoId, passName, limit)}
                              disabled={passInfo.priceInfo?.chargeForFullCapacity}
                            >
                              +
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!paidPassInfoIds.includes(passInfoId) &&
                  vehiclePasses.map((vehiclePass, index) => (
                    <VehiclePassForm
                      key={index}
                      index={index}
                      vehiclePass={vehiclePass}
                      passInfoId={passInfoId}
                      errors={errors}
                      handleChange={(index, field, value) =>
                      handleVehicleChange(passInfoId, index, field, value)
                      }
                      handleCheckboxChange={index =>
                        handleCheckboxChange(passInfoId, index)
                      }
                      smsAlert={false}
                      duration={passInfo.durationInfo?.duration1}
                      initialStartDate={startDate}
                      initialEndDate={endDate}
                      maxEndDate={maxEndDateString}
                      promotionalAlert={false}
                      setSmsAlert={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                      setPromotionalAlert={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                    />
                  ))}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PassOptions;
