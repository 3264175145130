// import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';
import {
  Dispatch,
  SetStateAction,
  ReactElement,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as AddImg } from '../../assets/add.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import GenericButton from '../../common_lib_front/components/genericButton/genericButton';
import PassInfo, { newPassInfo } from '../../common_lib_front/types/passInfo';
import collectionTools from '../../common_lib_front/utilities/collectionTools';
import useVehicleConfigs, { PassInfoData } from '../../utils/useVehicleConfigs';
import VehicleInfoForm from './vehicleInfoForm';
import style from './vehicleInfoFormCollection.module.css';

const TITLES: Record<string, (t: TFunction) => React.ReactNode> = {
  default: (t: TFunction) => (
    <>
      <h3 className={`${style.title} textColorSuperDark `}>{t('Pass Details')}</h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        {t('Enter pass details below for each pass being purchased')}
        <h4>If you are bringing a rental car, please check the rental car box below.</h4>
      </p>
    </>
  ),
  watercolor: () => (
    <>
      <h3 className={`${style.title} textColorSuperDark `}>Wristbands</h3>
      <p className={`${style.subTitle} textColorSuperDark `}>
        Wristbands used to access the community&apos;s amenities. The community controls
        the maximum number authorized for each property. Please add the names of each
        guest in your party below who will be needing access to the community&apos;s
        amenities. Anyone 5 years or older is required to wear a wristband. If the number
        of fields exceeds the number in your party, please leave the name space blank. If
        you need to request more, please reach out to your rental manager.
        <h4>
          Please Note: Guests are required to return all wristbands; lost or replacement
          wristbands are $50 each.
        </h4>
      </p>
    </>
  ),
};

type VehicleInfoFormColectionProps = {
  defaultPassDates?: { startDate: string; endDate: string };
  data: Array<PassInfo>;
  setData: (data: Array<PassInfo>) => void;
  allowAdd?: boolean | ((p: PassInfoData) => boolean);
  max?: number;
  allowRemove?: boolean | ((pass: PassInfo, idx: number) => boolean);
  showDriverForm?: boolean;
  formTitle?: (info: PassInfo, idx: number) => string;
  showIsRental?: boolean;
  showViewBtn?: boolean;
  showSaveBtn?: boolean;
  showMyPassesLink?: boolean;
  showTitle?: boolean;
  submitHandler?: () => void;
  removeHandler?: (o: { variables: { passId: string; vehicleId: string } }) => void;
  hideDates?: boolean;
  setHideSubmit?: Dispatch<SetStateAction<boolean>>;
};

export const VehicleInfoFormCollection = (
  props: VehicleInfoFormColectionProps,
): ReactElement => {
  const {
    data,
    setData,
    allowAdd,
    allowRemove,
    showDriverForm,
    removeHandler,
    max,
    showIsRental,
    showViewBtn,
    showSaveBtn,
    showTitle,
    submitHandler,
    defaultPassDates,
    showMyPassesLink,
    hideDates,
    setHideSubmit,
  } = props;

  const { passInfoDatas, vehicleConfig, guestName } = useVehicleConfigs();

  const addablePassInfoDatas = useMemo(() => {
    if (typeof allowAdd === 'function') {
      return passInfoDatas.filter(allowAdd);
    } else if (allowAdd) {
      return passInfoDatas;
    }
    return [];
  }, [passInfoDatas, allowAdd]);

  const { communityId } = useContext(CommunityContext);
  const { add, update, remove } = collectionTools<PassInfo>({
    newElement: (d?: Partial<PassInfo>) =>
      newPassInfo({
        ...d,
      }),
    data,
    setData,
  });
  /*
  ============ WARNING CAUSES RACE CONDITION. NOT BEEN FIXED ============
  // useEffect(() => {
  //   // To have a default pass type when have only one pass type
  //   if (allowAdd && allowRemove && data.length === 0 && passInfoDatas.length === 1) {
  //     add({
  //       ...defaultPassDates,
  //       passInfoId: passInfoDatas[0].passInfoId,
  //     });
  //   }
  //   if (allowRemove && max && max < data.length) {
  //     remove(data.length - 1);
  //   }
  // }, [
  //   add,
  //   defaultPassDates,
  //   data.length,
  //   passInfoDatas.length,
  //   max,
  //   passInfoDatas,
  //   remove,
  //   allowAdd,
  //   allowRemove,
  // ]);

  // adding breadcrumbs for suspicious edits
  // replaced by proxies
  // useEffect(() => {
  //   if (data.some(p => !p.passId)) {
  //     Sentry.addBreadcrumb({
  //       type: 'Suspicious data',
  //       category: 'Questionable data',
  //       message: 'Pas data may have been eddited and now does not include a passId',
  //       data: Object.assign({}, data || {}),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, data.length, data.map(p => p.passId)]);
  */

  const { t } = useTranslation();

  return (
    <div className={style.infoBox}>
      {showTitle ? (TITLES[communityId] || TITLES.default)(t) ?? TITLES.default(t) : null}

      {data.map((elem: PassInfo, idx: number) => {
        const passInfoData = passInfoDatas.find(
          pid => pid.passInfoId === elem.passInfoId,
        );
        return (
          <div className={style.collectionBox} key={idx}>
            <VehicleInfoForm
              key={elem.passId ? elem.passId : idx}
              idx={idx}
              data={elem}
              config={vehicleConfig[elem.passInfoId] || undefined}
              // title={formTitle ? formTitle(elem, idx + 1)
              // : `${t('Community Access Pass')} #${idx + 1}`}
              title={`#${idx + 1} ${passInfoData?.name || 'Access Pass'}`}
              description={passInfoData?.description}
              removeHandler={() => {
                if (elem.passId && removeHandler) {
                  removeHandler({
                    variables: {
                      passId: elem.passId,
                      vehicleId: elem.vehicle?.vehicleId || '',
                    },
                  });
                }
                remove(idx);
              }}
              updateHandler={(obj: any) => update(idx, obj)}
              allowRemove={
                typeof allowRemove === 'function' ? allowRemove(elem, idx) : allowRemove
                // elem.passType === 'invited-guest' ? false : allowRemove
              }
              showDriverForm={showDriverForm}
              showIsRental={showIsRental}
              showViewBtn={showViewBtn}
              allowEditDates={!defaultPassDates?.startDate || !defaultPassDates?.endDate}
              guestName={guestName}
              hideDates={hideDates}
              setHideSubmit={setHideSubmit}
            />
          </div>
        );
      })}
      {allowAdd && (max ? max > data.length : true) ? (
        <div className={style.btnBox}>
          <table className={style.passTypeTable}>
            <tbody className={style.passTypeTable}>
              {addablePassInfoDatas.map(p => (
                <tr key={p.passInfoId} title={p.description} className={style.addBtnBox}>
                  <td>{p.name}</td>
                  <td>
                    <GenericButton
                      title="Add"
                      icon={() => <AddImg className={style.addBtnImg} />}
                      clickHandler={() =>
                        add({
                          ...defaultPassDates,
                          passInfoId: p.passInfoId,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <GenericButton
              icon={(className) => (<AddImg className={style.addBtnImg} />)}
              title=""
              clickHandler={() => add(defaultPassDates)}
            />
            <div className={`${style.btnText} textColorSecondary `}>{t('Add a Pass')}</div> */}
        </div>
      ) : (
        <></>
      )}
      <div className={style.btn}>
        {showSaveBtn ? (
          <GenericButton
            clickHandler={submitHandler}
            color="blue"
            size="large"
            title={t('Save Changes')}
          />
        ) : null}
      </div>
      <div className={style.btn}>
        {showMyPassesLink ? (
          <Link to="/guest/my-passes">
            <GenericButton outline="large" title={t('My Passes')} />
          </Link>
        ) : null}
      </div>
    </div>
  );
};
VehicleInfoFormCollection.defaultProps = {
  defaultPassDates: undefined,
  allowAdd: true,
  max: undefined,
  allowRemove: false,
  showDriverForm: false,
  formTitle: undefined, // (data:PassInfo, idx:number) => `Community Access Pass #${idx}`,
  showIsRental: true,
  showViewBtn: false,
  showSaveBtn: true,
  showTitle: false,
  submitHandler: undefined,
  removeHandler: undefined,
  showMyPassesLink: false,
};
export default VehicleInfoFormCollection;
