import CloseIcon from '@mui/icons-material/Close';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import { getCommunityId } from '../../common_lib_front/communityConfigs/getCommunityId';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';
import store from '../../common_lib_front/utilities/store';
import style from './2faModal.module.css';
import { GET_VERIFICATION, VERIFY_CODE } from './requests';
import { Step1of2fa } from './step1';
import { Step2of2fa } from './step2';
import { BootstrapDialog } from './styledComponents';
import { contactMethods, otpType } from './types';

interface VerificationModalProps {
  onClose: () => void;
  onSuccess: (method: 'mobile' | 'email', verificationId: string) => void;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  isReconfirm?: boolean;
}

const VerificationModal: React.FC<VerificationModalProps> = ({
  onClose,
  onSuccess,
  phoneNumber,
  email,
  firstName,
  lastName,
  isReconfirm,
}) => {
  const [selectedMethod, setSelectedMethod] = useState<contactMethods>(!!phoneNumber ? 'mobile' : 'email');
  const [step, setStep] = useState(1); // 1 for method selection, 2 for code entry
  const [otp, setOtp] = useState<otpType>(['','','','','','']);
  const [verificationError, setVerificationError] = useState<string>('');
  const [codeGenerationError, setCodeGenerationError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [resent, setResent] = useState(false);
  const communityId = getCommunityId();

  useEffect(() => {
    if (email === '' && phoneNumber === '') {
      onClose();
    }
  }, [email, phoneNumber, onClose]);

  const getVerificationCode = async (userContact: string, twoFactorMethod: string) => {
    const data = await backendClient.query({
      query: GET_VERIFICATION,
      fetchPolicy: 'no-cache',
      variables: { userContact, twoFactorMethod },
    });
    if (data.data.initiateTwoFactorAuth.success) {
      console.log('Verification code sent successfully');
      setStep(2);
    } else {
      setLoading(false);
      setCodeGenerationError('Error sending verification code');
      console.log('Error sending verification code');
    }
  };

  const verifyCode = async (
    verificationCode: string,
    twoFactorMethod: string,
    userContact: string,
    firstName: string,
    lastName: string,
    email: string | null,
    phone: string | null,
  ) => {
    const data = await backendClient.query({
      query: VERIFY_CODE,
      fetchPolicy: 'no-cache',
      variables: {
        verificationCode,
        twoFactorMethod,
        userContact,
        firstName,
        lastName,
        email: email || '',
        phone: phone || '',
      },
    });
    if (data.data.verifyTwoFactorAuth.success) {
      console.log(
        'Verification code verified successfully',
        data.data.verifyTwoFactorAuth,
      );
      setVerificationError('');
      store.dispatch({
        type: 'community/setCommunity',
        payload: {
          token: data.data.verifyTwoFactorAuth.token,
          community: { communityId },
        },
      });
      onSuccess(
        selectedMethod,
        data.data.verifyTwoFactorAuth.data!.verificationId,
      );
      onClose();
      // setStep(2);
    } else {
      setLoading(false);
      setVerificationError('Invalid verification code');
      setCodeGenerationError('Error sending verification code');
      console.log('Error sending verification code');
    }
  };
  const handleContinue = () => {
    if (selectedMethod === 'mobile' && phoneNumber) {
      setLoading(true);
      getVerificationCode(phoneNumber, 'mobile');
      console.log(`Selected method: ${selectedMethod}, Phone Number: ${phoneNumber}`);
    } else if (selectedMethod === 'email' && email) {
      setLoading(true);
      getVerificationCode(email, 'email');
      console.log(`Selected method: ${selectedMethod}, Email: ${email}`);
    }
  };

  const handleVerify = () => {
    verifyCode(
      otp.join(''),
      selectedMethod,
      selectedMethod === 'mobile' ? phoneNumber : email,
      firstName,
      lastName,
      email,
      phoneNumber,
    );
  };

  const handleResendCode = () => {
    if (selectedMethod === 'mobile' && phoneNumber) {
      getVerificationCode(phoneNumber, 'mobile');
      setVerificationError('');
      setResent(true);
      // Logic to resend code
      console.log(`Resend code to ${phoneNumber}`);
    } else if (selectedMethod === 'email' && email) { 
      getVerificationCode(email, 'email');
      setVerificationError('');
      setResent(true);
      // Logic to resend code
      console.log(`Resend code to ${email}`);
    }
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          switch (step) {
            case 1:
              handleContinue();
              break;
            case 2:
              handleVerify();
              break;
            default:
              console.error('Unhandled condition in 2fa modal');
          }
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }} id="customized-dialog-title">
          {isReconfirm && 'Your session is about to expire'}
          <br />
          Verify your identity
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {step === 1 ? (
            <>
              {codeGenerationError && (
                <Typography gutterBottom textAlign="center" color="error">
                  {codeGenerationError}
                </Typography>
              )}
              <Step1of2fa
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                phoneNumber={phoneNumber}
                email={email}
              />
            </>
          ) : (
            <>
              {verificationError && (
                <Typography gutterBottom textAlign="center" color="error">
                  {verificationError}
                </Typography>
              )}
              {resent && (
                <Typography gutterBottom textAlign="center" color="green">
                  Verification code resent successfully
                </Typography>
              )}
              <Step2of2fa
                selectedMethod={selectedMethod}
                phoneNumber={phoneNumber}
                email={email}
                otp={otp}
                setOtp={setOtp}
                handleResendCode={handleResendCode}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            sx={{
              margin: '10px',
              backgroundColor: '#115A84',
              color: '#fff',
              padding: '10px 100px',
              ':hover': {
                backgroundColor: '#115A84',
              },
              ':disabled': {
                backgroundColor: '#ccc', /* Greyed out */
                opacity: 0.6,
              },
            }}
            className={style.modalButton}
            variant="contained"
            type="submit"
            disabled={(selectedMethod === 'mobile' && !phoneNumber) || (selectedMethod === 'email' && !email)}
          >
            {step === 1 ? (loading === true ? 'Sending...' : 'Continue') : 'Verify'}
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default VerificationModal;
