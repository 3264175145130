import { ReactChild, ReactElement, useContext, useMemo } from 'react';
import { CommunityContext } from '../../communityConfigs/communityContextProvider';
import lbgStyle from './forgetPWPagesBackground.module.css';

interface Props {
  children?: ReactChild | Array<ReactChild>;
}

export default function ForgetPWPagesBackground(props: Props): ReactElement {
  const { children } = props;
  const {
    bigLogo,
    communityId,
    styling: communityStyling,
  } = useContext(CommunityContext);

  const imgClass = useMemo(() => {
    switch (communityId) {
      case 'grandcomplex':
      case 'inletreefclub':
        return lbgStyle.box__img;
      default:
        return undefined;
    }
  }, [communityId]);

  return (
    <div className={lbgStyle.container}>
      <div className={`${lbgStyle.box} white `}>
        <div className={`${lbgStyle.box__left}  linerGradient `}>
          {imgClass ? (
            <img src={bigLogo} alt="" className={imgClass} />
          ) : (
            <div
              style={{
                backgroundImage: `url("${communityStyling?.backgroundImage}")`,
                height: communityStyling?.backgroundImageHeight,
                width: communityStyling?.backgroundImageWidth,
                backgroundSize: communityStyling?.backgroundSize,
                backgroundPosition: communityStyling?.backgroundPosition,
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                top: '-56px',
              }}
            />
          )}
        </div>
        <div className={lbgStyle.box__right}>{children}</div>
      </div>
    </div>
  );
}
ForgetPWPagesBackground.defaultProps = {
  children: <></>,
};
