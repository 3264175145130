import { gql, TypedDocumentNode } from '@apollo/client';
import { backendResponse, backendTokenResponse } from '../../common_lib_front/types/backendResponse';
import { backendClient } from '../../common_lib_front/utilities/BackendAPI';

export const GET_VERIFICATION: TypedDocumentNode<{
  initiateTwoFactorAuth: backendResponse<{ verificationId: string }>
}, {
  userContact: string,
  twoFactorMethod: string // 'phone' | 'email'
}> = gql`
  mutation InitiateTwoFactorAuth($userContact: String!, $twoFactorMethod: String!) {
    initiateTwoFactorAuth(userContact: $userContact, twoFactorMethod: $twoFactorMethod) {
      success
      data {
        verificationId
      }
      error
    }
  }
`;

export const VERIFY_CODE: TypedDocumentNode<{
  verifyTwoFactorAuth: backendTokenResponse<{
    verificationId: string,
  }>,
}, {
  verificationCode: string,
  twoFactorMethod: string,
  userContact: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
}> = gql`
  mutation verifyTwoFactorAuth(
    $verificationCode: String!
    $twoFactorMethod: String!
    $userContact: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    verifyTwoFactorAuth(
      verificationCode: $verificationCode
      twoFactorMethod: $twoFactorMethod
      userContact: $userContact
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      success
      data {
        verificationId
      }
      error
      token
    }
  }
`;
