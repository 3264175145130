export default function isRefundOptionAllow(
  passStatus: string,
  paymentStatus: string,
  adminRefundOnly = false,
) {
  if (adminRefundOnly) {
    return false;
  }

  return (
    !['active', 'expired', 'refunded', undefined, null].includes(passStatus) &&
    ['paid', 'ach-pending'].includes(paymentStatus)
  );
}
