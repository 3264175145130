import { gql, useMutation } from '@apollo/client';

import { backendResponse } from '../types/backendResponse';
import { createPaymentSession } from './usePaymentSession';

const CHARGE_CUSTOMER = gql`
  mutation ChargeCustomer(
    $userId: String!
    $paymentMethodType: String!
    $paymentSessionId: String!
  ) {
    chargeCustomer(
      userId: $userId
      paymentMethodType: $paymentMethodType
      paymentSessionId: $paymentSessionId
    ) {
      success
      error
    }
  }
`;

const GET_PAYMENT_METHOD = gql`
  mutation GetPaymentMethod($userId: String!) {
    getPaymentMethod(userId: $userId) {
      success
      data {
        type
      }
      error
    }
  }
`;

const ATTACH_PAYMENT_METHOD = gql`
  mutation AttachPaymentMethod(
    $paymentMethodId: String!
    $customerId: String!
    $userId: String!
  ) {
    attachPaymentMethod(
      paymentMethodId: $paymentMethodId
      customerId: $customerId
      userId: $userId
    ) {
      success
      error
    }
  }
`;

type CHARGE_CUSTOMER_RESP = { data: { chargeCustomer: backendResponse<unknown> } };

const useChargeCustomer = () => {
  const [chargeCustomer, { data, loading, error }] = useMutation(CHARGE_CUSTOMER);
  const [callPaymentMethod] = useMutation(GET_PAYMENT_METHOD);
  const [attachPaymentMethod] = useMutation(ATTACH_PAYMENT_METHOD);

  const charge = async (
    userId: string,
    amount: number,
    paymentMethodId?: string,
    stripeCustomerId?: string,
    isHostLink?: boolean,
    isGuestLink?: boolean,
    registrationId?: string,
  ): Promise<{ response?: CHARGE_CUSTOMER_RESP; error?: { message: string } }> => {
    try {
      if (isGuestLink) {
        const attachResponse = await attachPaymentMethod({
          variables: {
            paymentMethodId,
            customerId: stripeCustomerId,
            userId,
          },
        });
      }

      // if (isHostLink) {
      const paymentResponse = await callPaymentMethod({
        variables: { userId },
      });
      if (registrationId) {
        // call payment session
        const paymentSessionDetails = await createPaymentSession({
          newPaymentSession: {
            registrationIds: [registrationId],
          },
          userId: userId,
          guestFlow: true,
        });
        if (
          paymentResponse.data?.getPaymentMethod.success &&
          paymentSessionDetails.data?.createPaymentSession.data?.paymentSessionId
        ) {
          const response = await chargeCustomer({
            variables: {
              userId,
              paymentMethodType: paymentResponse.data?.getPaymentMethod?.data?.type,
              paymentSessionId:
                paymentSessionDetails.data?.createPaymentSession.data?.paymentSessionId,
            },
          });
          if (response.data?.chargeCustomer.success) {
            return { response: response as any };
          } else {
            console.error('Payment failed:', response.data?.chargeCustomer.error);
            return { error: { message: response.data?.chargeCustomer.error } };
          }
        } else {
          console.error(
            'Failed to get payment method:',
            paymentResponse.data?.getPaymentMethod.error,
          );
          return { error: paymentResponse.data?.getPaymentMethod.error };
        }
      }
      // }
    } catch (e) {
      console.error('Mutation error:', e);
      return { error: e as any };
    }
    // TODO this line below is definitely tech debt. this hook needs to be cleaned up
    return { error: { message: 'Unexpected error occurred while processing payment' } };
  };

  return { charge, data, loading, error };
};

export default useChargeCustomer;
