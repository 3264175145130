import { gql, TypedDocumentNode, useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import { ChipsInput } from '../../../common_lib_front/components/ChipsInput/ChipsInput';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import doValidate from '../../../common_lib_front/components/inviteGuestForm/useRentalValidation';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import useGetMyGuests from '../../../common_lib_front/hooks/useMyGuest';
import useOrderPrice, { PassPrice } from '../../../common_lib_front/hooks/useOrderPrice';
import useProperty from '../../../common_lib_front/hooks/useProperty';
import { backendResponse } from '../../../common_lib_front/types/backendResponse';
import { newVehicleInfo } from '../../../common_lib_front/types/vehicleInfo';
import { Auto2fa, auto2faApi } from '../../../components/2faModal/auto2fa';
import { useInviteGuest } from '../../../hooks/useInviteGuest';
import usePassGroups from '../../../utils/usePassGroups';
import useVehicleConfigs, { PassInfoData } from '../../../utils/useVehicleConfigs';
import {
  ADD_PASS,
  ADD_PASS_RES,
  ADD_PASS_VARS,
} from '../../registration/registerVehicle/registerVehicleRequests';
import style from './getPasses.module.css';
import { mapPassIdsToVehicles } from './mapPassIdsToVehicles';
import { mutateWrapperForVehiclePass } from './mutateWrapperForVehiclePass';
import PassOptions from './passInfoComponent';
import { GetPassesData, VehiclePass } from './types';
import { PassOptionErrorsType, validateVehicleData } from './validateVehicleData';

const errorMessages = {
  firstName: 'First Name is required.',
  lastName: 'Last Name is required.',
  email: 'Email or Phone number is required.',
  phoneNumber: 'Email or Phone number is required.',
  startDate: 'Check-In & Check-Out dates are required.',
  endDate: 'Check-In & Check-Out dates are required.',
};

type AddPassResponse = {
  data?: {
    addPassToInvite?: {
      data?: { passes?: any[] }[];
      error?: string;
      success?: boolean;
    };
  };
};

type ErrorsType = {
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  phoneNumber?: boolean;
  startDate?: boolean;
  endDate?: boolean;
};

const GROUP_KEY = 'Guest V2';

export default function GetPasses(): ReactElement {
  const history = useHistory();
  const { communityId, featuresConfig } = useContext(CommunityContext);
  const { propertySlug } = useParams<{ propertySlug: string }>();

  const { registrationId } = useParams<{ registrationId: string }>();
  const [shouldPrefillForm, setShouldPrefillForm] = useState(false);

  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [smsAlert, setSmsAlert] = useState<boolean>(false);
  const [promotionalAlert, setPromotionalAlert] = useState<boolean>(false);
  const { inviteGuests, data, loading: inviteLoading } = useInviteGuest();
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const twoFaRef = useRef<auto2faApi | null>(null);
  const [isVerified, setIsVerified] = useState(twoFaRef.current?.isVerified() || false);

  const { data: myGuestDetails, error: guestDetailsError } = useGetMyGuests({
    registrationIds: registrationId ? [registrationId] : [],
    propertySlug: propertySlug,
  });

  const { data: passBuilderGroups } = usePassGroups();

  const today = new Date().toISOString().split('T')[0];
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const [getPassesData, setGetPassesData] = useState<GetPassesData>({
    firstName: '',
    email: '',
    phoneNumber: '',
    startDate: '',
    endDate: '',
    lastName: '',
    guestNames: [],
  });

  const [guestInfo, setGuestInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });
  const [rentalInfo, setRentalInfo] = useState({
    address: '',
    arrivalDate: '',
    departureDate: '',
    guestNames: [] as string[],
  });

  const [passes, setPasses] = useState<any>([]);
  const [passInfoId, setPassInfoId] = useState<PassInfoData[]>([]);
  const [days, setDays] = useState<number>(0);
  const [vehiclePassesByType, setVehiclePassesByType] = useState<{
    [passInfoId: string]: VehiclePass[];
  }>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errors, setErrors] = useState<ErrorsType>({});
  const [passOptionErrors, setPassOptionErrors] = useState<PassOptionErrorsType>({});
  const [addPass] = useMutation<ADD_PASS_RES, ADD_PASS_VARS>(ADD_PASS);

  useEffect(() => {
    if (myGuestDetails?.length) {
      setShouldPrefillForm(true);
      setGuestInfo({
        firstName: myGuestDetails[0].guestInfo.firstName,
        lastName: myGuestDetails[0].guestInfo.lastName,
        email: myGuestDetails[0].guestInfo.email,
        phoneNumber: myGuestDetails[0].guestInfo.phoneNumber,
      });

      setGetPassesData({
        firstName: myGuestDetails[0].guestInfo.firstName,
        email: myGuestDetails[0].guestInfo.email,
        phoneNumber: myGuestDetails[0].guestInfo.phoneNumber,
        startDate: new Date(myGuestDetails[0].communityRental.arrivalDate)
          .toISOString()
          .split('T')[0],
        endDate: new Date(myGuestDetails[0].communityRental.departureDate)
          .toISOString()
          .split('T')[0],
        lastName: myGuestDetails[0].guestInfo.lastName,
        guestNames: myGuestDetails[0]?.communityRental?.guestNames || [],
      });
    }
  }, [myGuestDetails]);

  useEffect(() => {
    if (getPassesData.startDate && getPassesData.endDate) {
      const startDate = new Date(getPassesData.startDate);
      const endDate = new Date(getPassesData.endDate);
      const timeDiff = endDate.getTime() - startDate.getTime();
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      setDays(diffDays);
    }
  }, [getPassesData]);

  const {
    data: propertyDetail,
    loading: queryLoading,
    error: propertyError,
  } = useProperty({ propertySlug: propertySlug || '' });
  const { passInfoDatas, vehicleConfig } = useVehicleConfigs();

  useEffect(() => {
    setVehiclePassesByType(prevState => {
      let chargeForFullCapacityPassInfos;
      if (!registrationId) {
        chargeForFullCapacityPassInfos = passInfoDatas.filter(
          p => p.priceInfo?.chargeForFullCapacity && p.portal === 'guest',
        );
      }
      const copy = {
        ...prevState,
      };
      chargeForFullCapacityPassInfos?.forEach(cffcPassInfo => {
        if (!prevState[cffcPassInfo.passInfoId]?.length) {
          copy[cffcPassInfo.passInfoId] = [newVehicleInfo({}) as unknown as VehiclePass];
        }
      });
      return copy;
    });
  }, [passInfoDatas, setVehiclePassesByType]);

  const handleChange =
    (field: keyof GetPassesData) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setGetPassesData({
        ...getPassesData,
        [field]: e.target.value,
      });
    };

  const handlePhoneChange = (phone: string) => {
    setGetPassesData({
      ...getPassesData,
      phoneNumber: phone,
    });
  };

  const handleVehicleChange = (
    passInfoId: string,
    index: number,
    field: keyof VehiclePass,
    value: string,
  ) => {
    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]:
        prevState[passInfoId]?.map((vehiclePass, i) =>
          i === index ? { ...vehiclePass, [field]: value } : vehiclePass,
        ) || [],
    }));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleCheckboxChange = (passInfoId: string, index: number) => {
    setVehiclePassesByType(prevState => {
      const updatedPasses =
        prevState[passInfoId]?.map((vehiclePass, i) => {
          if (i === index) {
            const updatedPass = {
              ...vehiclePass,
              dontKnowLicense: !vehiclePass.dontKnowLicense,
            };
            if (updatedPass.dontKnowLicense) {
              updatedPass.licensePlate = '';
            }
            return updatedPass;
          }
          return vehiclePass;
        }) || [];

      return {
        ...prevState,
        [passInfoId]: updatedPasses,
      };
    });
  };

  const addVehiclePass = (passInfoId: string, passName: string, limit: number | null) => {
    if (limit !== null && limit > 0) {
      const prevLength = vehiclePassesByType[passInfoId]?.length || 0;
      if (prevLength >= limit) {
        setErrorMessage(
          `The maximum limit of ${limit} ${passName} passes has been reached. You cannot add more passes for this type.`,
        );
        return;
      }
    }

    setVehiclePassesByType(prevState => ({
      ...prevState,
      [passInfoId]: [
        ...(prevState[passInfoId] || []),
        {
          make: '',
          type: '',
          color: '',
          licensePlate: '',
          dontKnowLicense: false,
          primaryDriverName: '',
          vehicleModel: '',
          fleetNumber: '',
          licensePlateState: '',
          licensePrimaryDriver: '',
          startDate: '',
          endDate: '',
        },
      ],
    }));

    if (getPassesData.startDate && getPassesData.endDate) {
      setPasses((prevState: any) => [
        ...prevState,
        {
          passInfoId,
          startDate: vehiclePassesByType.startDate,
          endDate: vehiclePassesByType.endDate,
          registrationId: registrationId ?? '', //added for testing
        },
      ]);
    }

    console.log({ vehiclePassesByType });
  };

  const removeVehiclePass = (passInfoId: string) => {
    setVehiclePassesByType(prevState => {
      const updatedVehiclePasses = (prevState[passInfoId] || []).slice(0, -1);

      setPasses((prevPasses: any) => {
        const updatedPasses = prevPasses.filter(
          (p: any) => p.passInfoId !== passInfoId || updatedVehiclePasses.length > 0,
        );

        return updatedPasses;
      });

      return {
        ...prevState,
        [passInfoId]: updatedVehiclePasses,
      };
    });
  };

  useEffect(() => {
    const passInfoGroupList = passBuilderGroups?.getPassGroups.data?.find(
      pg => pg.name === GROUP_KEY,
    )?.passInfoList;

    if (passInfoGroupList) {
      const selectedPassInfos = passInfoGroupList
        .map(piid => passInfoDatas.find(pid => pid.passInfoId === piid))
        .filter(pid => !!pid) as PassInfoData[];
      setPassInfoId(selectedPassInfos);
    } else {
      const selectedPassInfos = passInfoDatas.filter(
        passInfoData => passInfoData.portal === 'guest',
      );
      setPassInfoId(selectedPassInfos);
    }
  }, [passInfoDatas, passBuilderGroups]);

  // useEffect(() => {
  //   const filteredPassInfoIds = passInfoId.filter(passInfoData => {
  //     const duration = passInfoData?.durationInfo?.duration1;
  //     const isMatchingPass = passInfoData.portal === 'guest' &&
  //       (typeof duration === 'undefined' || duration === null || duration >= days);
  //       console.log('filteredPassInfoIds', isMatchingPass);
  //     return isMatchingPass;
  //   });
  //   setPassInfoId(filteredPassInfoIds);
  // }, [passInfoDatas, days]);

  const passInfoFilter = (passInfoData: PassInfoData) => {
    // Check for missing fields or incorrect structure
    const maxDuration = passInfoData!.durationInfo!.duration1!;
    if (passInfoData.portal !== 'guest') {
      return false;
    }

    if (!getPassesData.startDate || !getPassesData.endDate) {
      return true;
    }
    return days <= maxDuration;
  };
  const {
    totalPrice: orderPriceTotalPrice,
    fees: orderPriceFees,
    passes: orderPricePasses,
    passesByType: orderPricePassesByType,
    loading: orderPriceLoading,
  } = useOrderPrice(passes, {
    defaultAddress: propertyDetail?.address,
  });

  useEffect(() => {
    setGuestInfo({
      firstName: getPassesData.firstName,
      lastName: getPassesData.lastName,
      email: getPassesData.email,
      phoneNumber: getPassesData.phoneNumber,
    });

    setRentalInfo({
      address: propertyDetail?.address || '',
      arrivalDate: getPassesData.startDate,
      departureDate: getPassesData.endDate,
      guestNames: getPassesData.guestNames,
    });
  }, [propertyDetail, getPassesData]);

  const isGuestLink =
    propertyDetail?.guestPay && propertyDetail.guestPropertySlug === propertySlug;
  // const totals = calculateTotals();
  // const { totalCount, totalAmount } = aggregateTotals();
  // Validation logic
  const validateForm = () => {
    const { firstName, lastName, email, phoneNumber, startDate, endDate } = getPassesData;
    const errors: ErrorsType = {};

    if (!firstName) {
      errors.firstName = true;
    }
    if (!lastName) {
      errors.lastName = true;
    }
    if (!email && !phoneNumber) {
      errors.email = true;
      errors.phoneNumber = true;
    }
    if (!startDate) {
      errors.startDate = true;
    }
    if (!endDate) {
      errors.endDate = true;
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
      return vehiclePassesByType[passInfoId].map(p => ({
        passInfoId,
        startDate: p.startDate,
        endDate: p.endDate,
        registrationId: registrationId ?? '', //added for testing
      }));
    });

    setPasses(updatedPasses);
  }, [vehiclePassesByType]);

  const handleContinue = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (orderPricePasses?.length === 0) {
      setErrorMessage('Please add at least one pass option.');
      return;
    }

    setLoading(true);

    try {
      const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
        return vehiclePassesByType[passInfoId].map(p => ({
          passInfoId,
          startDate: p.startDate,
          endDate: p.endDate,
        }));
      });

      setPasses(updatedPasses);
      setErrorMessage('');

      const validationResult = validateVehicleData(vehiclePassesByType, vehicleConfig);
      if (validationResult.valid && passes.length > 0) {
        const validate = await doValidate({
          passesRentals: [
            {
              rentalInfo: {
                address: rentalInfo.address,
                arrivalDate: rentalInfo.arrivalDate,
                departureDate: rentalInfo.departureDate,
              },
              passes: passes.map((pass: any) => ({
                passInfoId: pass.passInfoId,
                endDate: pass.endDate,
                startDate: pass.startDate,
              })),
            },
          ],
        });

        if (
          validate.data.bulkValidate.data &&
          validate?.data?.bulkValidate?.data?.length > 0 &&
          validate.data.bulkValidate.data?.[0] !== ''
        ) {
          setErrorMessage(validate.data.bulkValidate.data?.[0] || '');
        } else {
          const invites = [
            {
              guestInfo: {
                firstName: guestInfo.firstName,
                lastName: guestInfo.lastName,
                email: guestInfo.email,
                phoneNumber: guestInfo.phoneNumber,
              },
              hostWillPay: true,
              passes: passes.map((pass: any) => ({
                passInfoId: pass.passInfoId,
                endDate: pass.endDate,
                startDate: pass.startDate,
              })),
              rentalInfo: {
                address: rentalInfo.address,
                arrivalDate: rentalInfo.arrivalDate,
                departureDate: rentalInfo.departureDate,
                guestNames: rentalInfo.guestNames,
              },
            },
          ];
          const result = await inviteGuests(invites, propertySlug, true);
          const data = result?.inviteGuests?.data ?? [];
          const addedPasses = data.length > 0 ? data[0].passes ?? [] : [];
          const registrationId =
            data.length > 0 ? data[0].registration?.registrationId ?? '' : '';
          if (addedPasses.length > 0) {
            const formattedPasses = addedPasses.reduce<Record<string, any[]>>(
              (acc, curr) => {
                const { passInfoId } = curr;
                if (passInfoId !== undefined) {
                  if (!acc[passInfoId]) {
                    acc[passInfoId] = [];
                  }
                  acc[passInfoId].push(curr);
                }
                return acc;
              },
              {},
            );

            const passesWithVehicle = mapPassIdsToVehicles(
              formattedPasses,
              vehiclePassesByType,
              propertySlug,
              {
                destination: propertyDetail?.address || '',
                primaryDriverName:
                  (getPassesData.firstName || '') + ' ' + (getPassesData.lastName || ''),
              },
            );
            await Promise.all(
              passesWithVehicle.map((passWithVehicle: any) =>
                mutateWrapperForVehiclePass(passWithVehicle),
              ),
            );
            const nextPageURL = `/guest/${propertySlug}/${registrationId}/education`;
            scrollToTop();

            history.replace(nextPageURL);
          } else {
            if (result?.inviteGuests?.error === 'Overlapping Reservations') {
              setErrorMessage(
                'The dates you’ve requested already have too many overlapping reservations and have reached their limit. Please contact your host for further questions.',
              );
            } else {
              setErrorMessage(result?.inviteGuests?.error || '');
            }
          }
        }
      } else {
        setPassOptionErrors(validationResult.errors || {});
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('An error occurred while sending invitations.');
    } finally {
      setLoading(false);
    }
  };
  const handleContinueWithRegistration = async (e: any) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (orderPricePasses?.length === 0) {
      setErrorMessage('Please add at least one pass option.');
      return;
    }

    setLoading(true);

    try {
      const updatedPasses = Object.keys(vehiclePassesByType).flatMap(passInfoId => {
        return vehiclePassesByType[passInfoId].map(p => ({
          passInfoId,
          startDate: p.startDate,
          endDate: p.endDate,
          registrationId: registrationId,
        }));
      });
      setPasses(updatedPasses);
      setErrorMessage('');

      const validationResult = validateVehicleData(vehiclePassesByType, vehicleConfig);
      if (validationResult.valid && passes.length > 0) {
        const validate = await doValidate({
          passesRentals: [
            {
              rentalInfo: {
                address: rentalInfo.address,
                arrivalDate: rentalInfo.arrivalDate,
                departureDate: rentalInfo.departureDate,
              },
              passes: passes.map((pass: any) => ({
                passInfoId: pass.passInfoId,
                endDate: pass.endDate,
                startDate: pass.startDate,
              })),
            },
          ],
        });

        console.log({ validatefromregistrationContinue: validate });
        if (
          validate.data.bulkValidate.data &&
          validate?.data?.bulkValidate?.data?.length > 0 &&
          validate.data.bulkValidate.data?.[0] !== ''
        ) {
          setErrorMessage(validate.data.bulkValidate.data?.[0] || '');
        } else {
          const results = (await Promise.all(
            passes.map((pass: any) =>
              addPass({
                variables: {
                  passInfoId: pass.passInfoId,
                  registrationId,
                },
              }),
            ),
          )) as AddPassResponse[]; // Explicitly cast to the expected type

          if (results[0].data?.addPassToInvite?.success !== true) {
            setErrorMessage(results[0].data?.addPassToInvite?.error || '');
          } else {
            const addedPasses = results.map(
              result => result?.data?.addPassToInvite?.data?.[0]?.passes?.[0] || {},
            );
            if (addedPasses.length > 0) {
              const formattedPasses = addedPasses.reduce<Record<string, any[]>>(
                (acc: Record<string, any[]>, curr: { passInfoId?: string }) => {
                  const { passInfoId } = curr;
                  if (passInfoId !== undefined) {
                    if (!acc[passInfoId]) {
                      acc[passInfoId] = [];
                    }
                    acc[passInfoId].push(curr);
                  }
                  return acc;
                },
                {},
              );
              const passesWithVehicle = mapPassIdsToVehicles(
                formattedPasses,
                vehiclePassesByType,
                propertySlug,
                {
                  destination: propertyDetail?.address || '',
                  primaryDriverName:
                    (getPassesData.firstName || '') +
                    ' ' +
                    (getPassesData.lastName || ''),
                },
              );
              await Promise.all(
                passesWithVehicle.map((passWithVehicle: any) =>
                  mutateWrapperForVehiclePass(passWithVehicle),
                ),
              );
              const nextPageURL = `/guest/${propertySlug}/${registrationId}/education`;
              scrollToTop();

              history.replace(nextPageURL);
            }
          }
        }
      } else {
        setPassOptionErrors(validationResult.errors || {});
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('An error occurred while sending invitations.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      if (targetDivRef.current) {
        const elementPosition = targetDivRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - 200; // Adjust scroll position by -100px

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
        // targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [errorMessage]);

  useEffect(() => {
    if (queryLoading || !propertySlug || !passInfoDatas) {
      setLoading(true);
      return;
    }
    if (propertyError) {
      setRedirectPath(`/guest/${propertySlug}/link-disabled`);
      setLoading(false);
    } else {
      if (propertyDetail) {
        if (
          (propertyDetail?.hostPropertySlug == propertySlug &&
            propertyDetail?.hostPay === false) ||
          (propertyDetail?.guestPropertySlug == propertySlug &&
            propertyDetail?.guestPay === false)
        ) {
          setRedirectPath(`/guest/${propertySlug}/link-disabled`);
        }
      }
      setLoading(false);
    }
  }, [propertyDetail, propertySlug, queryLoading, passInfoDatas, propertyError]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className={style.container}>
      <div className={`${style.box} white noShadow`}>
        <form
          className={`${style.passForm}`}
          onSubmit={shouldPrefillForm ? handleContinueWithRegistration : handleContinue}
          onKeyDown={ev => {
            if (
              ev.key === 'Enter' &&
              ev.target instanceof HTMLInputElement &&
              ev.target.id === 'guest-names-inpt'
            ) {
              ev.preventDefault();
            }
          }}
        >
          <h1 className={`${style.subHeaderTitle} textColorBlack`}>
            Get your access passes.
          </h1>
          <div className={`${style.mainBox}`}>
            <div className={`${style.innerBox}`}>
              <div className={`${style.propertyDetail}`}>
                <div>
                  <h3 className={`${style.propertyHeading}`}>Property Details</h3>
                </div>
                <div className={`${style.propertyAddress}`}>
                  {propertyDetail?.address}, {propertyDetail?.city},{' '}
                  {propertyDetail?.state} {propertyDetail?.zipCode}
                </div>
              </div>
            </div>

            <div className={style.alert}>
              <div className={style.alertBox}>
                <GenericAlert color={alertColor} title={alert} hidden={!alert} />
              </div>
            </div>

            {errorMessage != '' && (
              <div ref={targetDivRef} className={style.errorContainer}>
                <p className={style.errorMessage}>{errorMessage}</p>
              </div>
            )}

            <div className={style.guestDetailBox}>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="firstName-inpt" className={style.label}>
                    First Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="firstName-inpt"
                      inputType="text"
                      inputValue={getPassesData.firstName}
                      highlightOnFocus
                      changeHandler={handleChange('firstName')}
                      inputPlaceholder="Add your first name"
                      disabled={shouldPrefillForm}
                    />
                    {errors.firstName ? (
                      <span style={{ color: 'red' }}>{errorMessages.firstName}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="lastName-inpt" className={style.label}>
                    Last Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="lastName-inpt"
                      inputType="text"
                      inputValue={getPassesData.lastName}
                      highlightOnFocus
                      changeHandler={handleChange('lastName')}
                      inputPlaceholder="Add your last name"
                      disabled={shouldPrefillForm}
                    />
                    {errors.lastName ? (
                      <span style={{ color: 'red' }}>{errorMessages.lastName}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="email-inpt" className={style.label}>
                    Email Address <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="email-inpt"
                      inputType="email"
                      inputValue={getPassesData.email}
                      highlightOnFocus
                      changeHandler={handleChange('email')}
                      inputPlaceholder="Enter your email address"
                      disabled={shouldPrefillForm}
                    />
                    {errors.email ? (
                      <span style={{ color: 'red' }}>{errorMessages.email}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="phone-inpt" className={style.label}>
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <PhoneInputWrapper
                      value={getPassesData.phoneNumber}
                      changeHandler={handlePhoneChange}
                      smallStandardInput
                      required={true}
                      placeHolder="Enter your phone number"
                      disabled={shouldPrefillForm}
                    />
                    {errors.phoneNumber ? (
                      <span style={{ color: 'red' }}>{errorMessages.phoneNumber}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={style.row}>
                <div className={style.box__inputField}>
                  <label htmlFor="check-in-inpt" className={style.label}>
                    Check-in Date <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="startDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.startDate}
                      highlightOnFocus
                      changeHandler={handleChange('startDate')}
                      inputPlaceholder="Choose the date"
                      min={today}
                      disabled={shouldPrefillForm}
                    />
                    {errors.startDate ? (
                      <span style={{ color: 'red' }}>{errorMessages.startDate}</span>
                    ) : null}
                  </div>
                </div>
                <div className={style.box__inputField}>
                  <label htmlFor="check-out-inpt" className={style.label}>
                    Check-out Date <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div className={style.inputBox}>
                    <InputField
                      required
                      smallSize
                      htmlFor="endDate-inpt"
                      inputType="date"
                      inputValue={getPassesData.endDate}
                      highlightOnFocus
                      changeHandler={handleChange('endDate')}
                      inputPlaceholder="Choose the date"
                      min={getPassesData.startDate}
                      disabled={shouldPrefillForm}
                    />
                    {errors.endDate ? (
                      <span style={{ color: 'red' }}>{errorMessages.endDate}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              {Boolean(featuresConfig?.guest?.guestNames) && (
                <div className={style.row}>
                  <div className={style.box__inputField}>
                    <label htmlFor="guest-names-inpt" className={style.label}>
                      Guest Names <span style={{ color: 'red' }}>*</span>
                    </label>
                    <ChipsInput
                      value={getPassesData.guestNames}
                      containerProps={{
                        className: style.inputBox,
                      }}
                      inputProps={{
                        id: 'guest-names-inpt',
                        placeholder: getPassesData.guestNames.length
                          ? ''
                          : 'Add names for all of your guests (Hit enter after each)',
                      }}
                      setValue={val =>
                        setGetPassesData(prev => ({
                          ...prev,
                          guestNames: val,
                        }))
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            {isVerified && (
              <>
                <h3 className={style.passOptLbl}>Pass Options:</h3>
                <PassOptions
                  passInfoId={passInfoId.filter(passInfoFilter)}
                  vehiclePassesByType={vehiclePassesByType}
                  orderPricePasses={orderPricePasses}
                  paidPassInfoIds={
                    myGuestDetails?.[0]?.passes
                      .filter(
                        p =>
                          p.paid === 'paid' && p.passInfo?.priceInfo?.chargeForFullCapacity,
                      )
                      .map(p => p.passInfoId) || []
                  }
                  days={days}
                  startDate={getPassesData.startDate}
                  endDate={getPassesData.endDate}
                  isGuestLink={isGuestLink}
                  addVehiclePass={addVehiclePass}
                  removeVehiclePass={removeVehiclePass}
                  handleVehicleChange={handleVehicleChange}
                  handleCheckboxChange={handleCheckboxChange}
                  errors={passOptionErrors}
                  priceSums={
                    orderPriceLoading
                      ? null
                      : Object.fromEntries(
                          Object.entries(orderPricePassesByType || {}).map(
                            ([piid, prices]) => [
                              piid,
                              prices.reduce((sum, val) => sum + (val.price || 0), 0),
                            ],
                          ),
                        )
                  }
                />
                <div className={style.innerBox}>
                  <div style={{ margin: '20px 10px 10px 0px' }}>
                    <h3>
                      Subtotal ({passes?.length}{' '}
                      {(passes?.length || 0) > 1 ? 'passes' : 'pass'})
                    </h3>
                  </div>
                  <div className={style.totals}>
                    {Object.entries(vehiclePassesByType || {}).map(([piid, vehiclePasses]) =>
                      vehiclePasses.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div key={piid} className={style.totalBox}>
                            <div
                              className={style.row}
                              style={{ margin: '10px 10px 10px 0px' }}
                            >
                              <p>
                                ({vehiclePasses.length ?? 0}){' '}
                                {passInfoDatas.find(p => p.passInfoId === piid)?.name ||
                                  'Access Pass'}
                              </p>
                              {isGuestLink && (
                                <p>
                                  $
                                  {orderPricePassesByType?.[piid]
                                    ?.reduce<number>((sum, val) => sum + (val.price ?? 0), 0)
                                    ?.toFixed(2) || '0.00'}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ),
                    )}
                    {orderPriceFees?.map(fee => (
                      <>
                        <div className={style.totalBox}>
                          <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                            <p>{fee.name || 'Fee'}</p>
                            <p>${fee.amount?.toFixed(2) || '0.00'}</p>
                          </div>
                        </div>
                      </>
                    ))}
                    {isGuestLink && (
                      <>
                        <hr className={style.horizontalLine} />
                        <div className={style.row} style={{ margin: '10px 10px 10px 0px' }}>
                          <h4>Total</h4>
                          <h4>
                            {(orderPricePasses?.length || 0) > 0
                              ? `$${orderPriceTotalPrice.toFixed(2)}`
                              : '$0'}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className={style.checkboxContainer} style={{ marginTop: '50px' }}>
                  <input
                    type="checkbox"
                    checked={smsAlert}
                    onChange={() => setSmsAlert(!smsAlert)}
                  />
                  <span className={style.checkboxText}>
                    Opt in to receive important SMS notifications about emergencies, safety
                    updates, and critical community alerts. We&apos;ll keep you informed
                    during any urgent situations.
                  </span>
                </div>
                <div className={style.checkboxContainer}>
                  <input
                    type="checkbox"
                    checked={promotionalAlert}
                    onChange={() => setPromotionalAlert(!promotionalAlert)}
                  />
                  <span className={style.checkboxText}>
                    Opt in to receive important SMS notifications about emergencies, safety
                    updates, and critical community alerts. We&apos;ll keep you informed
                    during any urgent situations.
                  </span>
                </div>
              </>
            )}
            <div className={`${style.innerBox} ${style.buttonBox}`}>
              <div className={style.buttonContainer}>
                {isVerified
                  ? (
                      !loading ? (
                        <button
                          className={style.button}
                          onClick={
                            shouldPrefillForm ? handleContinueWithRegistration : handleContinue
                          }
                          type="submit"
                        >
                          Continue
                        </button>
                      ) : (
                        <button className={style.button} type="button">
                          Loading...
                        </button>
                      )
                  ) : (
                    <button
                      className={style.button}
                      type="button"
                      onClick={() => {
                        if (twoFaRef.current) {
                          twoFaRef.current.setUserData({
                            firstName: getPassesData.firstName || '',
                            lastName: getPassesData.lastName || '',
                            phone: getPassesData.phoneNumber || '',
                            email: getPassesData.email || '',
                          });
                          twoFaRef.current.openModal();
                        }
                      }}
                    >
                      Continue
                    </button>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <Auto2fa
        apiRef={twoFaRef}
        onSuccess={() => {
          setIsVerified(true);
          const userData = twoFaRef.current?.getUserData();
          if (userData) {
            setGetPassesData((prev) => ({
              ...prev,
              firstName: prev.firstName || userData.firstName || '',
              lastName: prev.lastName || userData.lastName || '',
              phoneNumber: prev.phoneNumber || userData.phone || '',
              email: prev.email || userData.email || '',
            }));
          }
        }}
        onRehydrate={() => {
          setIsVerified(true);
          const userData = twoFaRef.current?.getUserData();
          if (userData) {
            setGetPassesData((prev) => ({
              ...prev,
              firstName: prev.firstName || userData.firstName || '',
              lastName: prev.lastName || userData.lastName || '',
              phoneNumber: prev.phoneNumber || userData.phone || '',
              email: prev.email || userData.email || '',
            }));
          }
        }}
      />
    </div>
  );
}
