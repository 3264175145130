import { Box, Link, TextField, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { contactMethods, otpType } from './types';

export function Step2of2fa({
  selectedMethod,
  phoneNumber,
  email,
  otp,
  setOtp,
  handleResendCode,
}: {
  selectedMethod: contactMethods,
  phoneNumber: string,
  email: string,
  otp: otpType,
  setOtp: (val: otpType) => void,
  handleResendCode: () => void,
}) {

  const handleOtpChange = (index: number, value: string) => {
    // Allow only numeric input
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp as otpType);

    // Move focus to the next input when a value is entered
    if (value && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }

    // Move focus to the previous input if the value is deleted
    if (!value && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const firstOtpInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (firstOtpInputRef.current) {
      firstOtpInputRef.current.focus();
    }
  }, []);

  return (
    <>
      <Typography gutterBottom textAlign="center">
        Enter the code sent to {selectedMethod === 'mobile' ? phoneNumber : email}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        gap={1}
        sx={{ margin: '2rem 3rem' }}
      >
        {otp.map((value, index) => (
          <TextField
            key={index}
            id={`otp-${index}`}
            size="small"
            variant="outlined"
            inputProps={{
              maxLength: 1,
              style: { textAlign: 'center', fontSize: '20px' },
            }}
            value={value}
            onChange={e => handleOtpChange(index, e.target.value)}
            onFocus={e => e.target.select()}
            sx={{ width: 50, backgroundColor: 'rgb(249 249 251)' }}
            inputRef={index === 0 ? firstOtpInputRef : null} // Auto-focus only on first input
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="center">
        <Link
          component="button"
          color="primary"
          underline="always"
          sx={{ mt: 2 }}
          onClick={handleResendCode}
        >
          Resend code
        </Link>
      </Box>
    </>
  );
}
