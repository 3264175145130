import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { Item, RadioWrapper } from './styledComponents';
import { contactMethods } from './types';

export function Step1of2fa({
  selectedMethod,
  setSelectedMethod,
  phoneNumber,
  email,
}: {
  selectedMethod: contactMethods,
  setSelectedMethod: (val: contactMethods) => void,
  phoneNumber: string,
  email: string,
}) {
  return (
    <>
      <Typography gutterBottom>
        How would you like to receive your one-time verification code?
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item
              selected={selectedMethod === 'mobile'}
              onClick={() => setSelectedMethod('mobile')}
            >
              <RadioWrapper>
                <Radio
                  checked={selectedMethod === 'mobile'}
                  value="mobile"
                  name="verification-method"
                  inputProps={{ 'aria-label': 'Mobile phone' }}
                  disabled={!phoneNumber}
                />
              </RadioWrapper>
              <PhoneAndroidOutlinedIcon sx={{ fontSize: '60px' }} />
              <Typography variant="subtitle1">Mobile phone</Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item
              selected={selectedMethod === 'email'}
              onClick={() => setSelectedMethod('email')}
            >
              <RadioWrapper>
                <Radio
                  checked={selectedMethod === 'email'}
                  value="email"
                  name="verification-method"
                  inputProps={{ 'aria-label': 'Email' }}
                  disabled={!email}
                />
              </RadioWrapper>
              <EmailOutlinedIcon sx={{ fontSize: '60px' }} />
              <Typography variant="subtitle1">Email</Typography>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
